import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ImpressumPage = () => (
  <Layout>
    <SEO title='Impressum' />
    <h1>Impressum</h1>
    <p>(Informationen gemäß §5 ECG, §14 UGB u. §25 MedienG)</p>
    <pre>
      ViSpecs GmbH
      <br />
      Schlager Patrick
      <br />
      Kirchengasse 76
      <br />
      2722 Weikersdorf am Steinfelde
    </pre>
    <p>
      Tel: 0660/6378449
      <br />
      Email: patrick.schlager@vispecs.com
    </p>
    <p>Behörde gemäß ECG: Magistrat der Stadt Wiener Neustadt</p>
    <p>
      Rechtsform: Gesellschaft m.b.H.
      <br />
      Firmenbuchnummer: FN 527066y
      <br />
      Firmenbuchgericht: Landesgericht Wiener Neustadt
      <br />
      UID: ATU75207456
      <br />
      Geschäftsführender Gesellschafter: Patrick Schlager
    </p>
    <p>Medieninhaber und Herausgeber: ViSpecs GmbH</p>
    <p>Website/App: Informationen, Neuigkeiten und Termine über ViSpecs GmbH</p>
  </Layout>
)

export default ImpressumPage
